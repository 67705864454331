@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';

.block-section14-section-wrap {
  padding: 48px 0;
  .content {
    //max-width: 410px;
  }
  .image-wrap {
    margin-bottom: 74px;
    position: relative;
    text-align: center;
    img {
      
    }
  }
  .button-wrap {
    text-align: center;
  }
  @include media-breakpoint-up(md) { 
    //padding: 96px 0;
    .row {
      align-items: center;
    }
    .image-wrap {
      margin-bottom: 0;
      img {
        //width: 100%;
      }
    }
    .button-wrap {
      text-align: left;
      margin-top: 48px;
    }
  }
}